import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'api/axios';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
};

export const getSalesActivity = createAsyncThunk('salesActivity/getSalesActivity', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`/emp-activity/list?page=${data?.pagination?.currentPage}&pageSize=${data?.pagination?.pageSize}`, data?.filter);


        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});
export const createSalesActivity = createAsyncThunk('salesActivity/createSalesActivity', async (value, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`/emp-activity/create`, { activity: value });
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});


export const salesActivityLogSlice = createSlice({
    name: 'salesActivity',
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSalesActivity.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSalesActivity.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(getSalesActivity.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })


    },
});

export const { showLoading } = salesActivityLogSlice.actions;

export default salesActivityLogSlice.reducer;
