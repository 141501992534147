import { AppstoreOutlined, MailOutlined, DesktopOutlined, UsergroupAddOutlined, UserSwitchOutlined, StarOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const appsNavTree = [
	{
		key: 'apps-project',
		path: `${APP_PREFIX_PATH}/dashboards`,
		title: 'Dashboard',
		icon: AppstoreOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'dashboard',
				path: `${APP_PREFIX_PATH}/dashboards`,
				title: 'Dashboard',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			// {
			// 	key: 'branch-performance',
			// 	path: `${APP_PREFIX_PATH}/dashboards/branch-performance`,
			// 	title: 'Branch Performance',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			// {
			// 	key: 'sales-advisor-performance',
			// 	path: `${APP_PREFIX_PATH}/dashboards/sales-advisor-performance`,
			// 	title: 'Sales Advisor Performance',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: 'Sales-advisor-activity-logs',
				path: `${APP_PREFIX_PATH}/dashboards/sales-advisor-activity-logs`,
				title: 'Sales Advisor Activity Logs',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'service-advisor-activity-logs',
				path: `${APP_PREFIX_PATH}/dashboards/service-advisors-activity-logs`,
				title: 'Service Advisor Activity Logs',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'team-member',
		path: `${APP_PREFIX_PATH}/dashboards`,
		title: 'Team Members',
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'team-members',
				path: `${APP_PREFIX_PATH}/team-members/team-members`,
				title: 'Team Members',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'branch-manager',
				path: `${APP_PREFIX_PATH}/team-members/branch-manager`,
				title: 'Branch Manager',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sales-advisers',
				path: `${APP_PREFIX_PATH}/team-members/sales-advisers-team`,
				title: 'Sales Advisors',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'service-advisers',
				path: `${APP_PREFIX_PATH}/team-members/service-advisers-team`,
				title: 'Service Advisors',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'Leads Management',
		path: `${APP_PREFIX_PATH}/leads-management/leads-management`,
		title: 'Leads Management',
		icon: MailOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: '/Profile Registration',
				path: `${APP_PREFIX_PATH}/profile-under-review`,
				title: 'Profile Registration',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'Hot Leads',
				path: `${APP_PREFIX_PATH}/leads-management/leads-management`,
				title: 'Hot Leads',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			// {
			// 	key: 'add-lead-source-status',
			// 	path: `${APP_PREFIX_PATH}/leads-management/add-lead-source-status`,
			// 	title: 'Add Lead Source & Status',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
		],
	},
	// {
	// 	key: 'profile-under-review',
	// 	path: `${APP_PREFIX_PATH}/profile-under-review`,
	// 	title: 'Profile Under Review',
	// 	icon: UserSwitchOutlined,
	// 	breadcrumb: false,
	// 	submenu: [],
	// },
	{
		key: 'subscriptions',
		path: `${APP_PREFIX_PATH}/subscriptions`,
		title: 'Subscriptions',
		icon: StarOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'subscription-plans',
				path: `${APP_PREFIX_PATH}/subscriptions/subscription-plans`,
				title: 'Subscription Plans',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'subscribed-users',
				path: `${APP_PREFIX_PATH}/subscriptions/subscribed-users`,
				title: 'Subscribed Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'expired-subscription-users',
				path: `${APP_PREFIX_PATH}/subscriptions/expired-subscription-users`,
				title: 'Expired Subscription Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'app-users',
		path: `${APP_PREFIX_PATH}/app-users`,
		title: 'App Users',
		icon: DesktopOutlined,
		breadcrumb: false,
		submenu: [
			// {
			// 	key: 'registered-users',
			// 	path: `${APP_PREFIX_PATH}/app-users/registered-users`,
			// 	title: 'Registered Users',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: 'drafted-profile-users',
				path: `${APP_PREFIX_PATH}/app-users/drafted-profile-users`,
				title: 'Drafted Profile Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'brides',
				path: `${APP_PREFIX_PATH}/app-users/brides`,
				title: 'Brides',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'grooms',
				path: `${APP_PREFIX_PATH}/app-users/grooms`,
				title: 'Grooms',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
];

export const branchManagerNavTree = [
	{
		key: 'bm-dashboards',
		path: `${APP_PREFIX_PATH}/dashboards`,
		title: 'Dashboards',
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'bm-dashboards',
				path: `${APP_PREFIX_PATH}/branch-manager/dashboard`,
				title: 'Dashboards',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'bm-branch-performance',
				path: `${APP_PREFIX_PATH}/branch-manager/branch-performance`,
				title: 'Branch Performance',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'bm-sales-advisor-performance',
				path: `${APP_PREFIX_PATH}/branch-manager/sales-advisor-performance`,
				title: 'Sales Advisor Performance',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'bm-Sales-advisor-activity-logs',
				path: `${APP_PREFIX_PATH}/branch-manager/sales-advisor-activity-logs`,
				title: 'Sales Advisor Activity Logs',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'bm-service-advisor-activity-logs',
				path: `${APP_PREFIX_PATH}/branch-manager/service-advisors-activity-logs`,
				title: 'Service Advisor Activity Logs',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'bm-team-member',
		path: `${APP_PREFIX_PATH}/branch-manager/team-members`,
		title: 'Team Members',
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [
			// {
			// 	key: 'bm-team-members',
			// 	path: `${APP_PREFIX_PATH}/branch-manager/team-members`,
			// 	title: 'Team Members',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			// {
			// 	key: 'bm-branch-manager',
			// 	path: `${APP_PREFIX_PATH}/branch-manager/branch-manager`,
			// 	title: 'Branch Manager',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: 'bm-sales-advisers',
				path: `${APP_PREFIX_PATH}/branch-manager/sales-advisers-team`,
				title: 'Sales Advisors',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'bm-service-advisers',
				path: `${APP_PREFIX_PATH}/branch-manager/service-advisers-team`,
				title: 'Service Advisors',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	// {
	// 	key: 'bm-leads-management',
	// 	path: `${APP_PREFIX_PATH}/branch-manager/leads-management`,
	// 	title: 'Leads Management',
	// 	icon: MailOutlined,
	// 	breadcrumb: false,
	// 	submenu: [
	// 		{
	// 			key: 'bm-leads-management',
	// 			path: `${APP_PREFIX_PATH}/branch-manager/leads-management`,
	// 			title: 'Leads Management',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 		{
	// 			key: 'bm-add-lead-source-status',
	// 			path: `${APP_PREFIX_PATH}/branch-manager/add-lead-source-status`,
	// 			title: 'Add Lead Source & Status',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 	],
	// },
	{
		key: 'Leads Management',
		path: `${APP_PREFIX_PATH}/leads-management/leads-management`,
		title: 'Leads Management',
		icon: MailOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: '/Profile Registration',
				path: `${APP_PREFIX_PATH}/profile-under-review`,
				title: 'Profile Registration',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'Hot Leads',
				path: `${APP_PREFIX_PATH}/leads-management/leads-management`,
				title: 'Hot Leads',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			// {
			// 	key: 'add-lead-source-status',
			// 	path: `${APP_PREFIX_PATH}/leads-management/add-lead-source-status`,
			// 	title: 'Add Lead Source & Status',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
		],
	},
	// {
	// 	key: 'bm-profile-under-review',
	// 	path: `${APP_PREFIX_PATH}/branch-manager/profile-under-review`,
	// 	title: 'Profile Under Review',
	// 	icon: UserSwitchOutlined,
	// 	breadcrumb: false,
	// 	submenu: [],
	// },
	{
		key: 'bm-subscriptions',
		path: `${APP_PREFIX_PATH}/branch-manager/subscriptions`,
		title: 'Subscriptions',
		icon: StarOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'bm-subscription-plans',
				path: `${APP_PREFIX_PATH}/branch-manager/subscriptions/subscription-plans`,
				title: 'Subscription Plans',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'bm-subscribed-users',
				path: `${APP_PREFIX_PATH}/branch-manager/subscriptions/subscribed-users`,
				title: 'Subscribed Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'bm-expired-subscription-users',
				path: `${APP_PREFIX_PATH}/branch-manager/subscriptions/expired-subscription-users`,
				title: 'Expired Subscription Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'bm-app-users',
		path: `${APP_PREFIX_PATH}/branch-manager/app-users`,
		title: 'App Users',
		icon: DesktopOutlined,
		breadcrumb: false,
		submenu: [
			// {
			// 	key: 'bm-registered-users',
			// 	path: `${APP_PREFIX_PATH}/branch-manager/app-users/registered-users`,
			// 	title: 'Registered Users',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: 'bm-drafted-profile-users',
				path: `${APP_PREFIX_PATH}/branch-manager/app-users/drafted-profile-users`,
				title: 'Drafted Profile Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'bm-brides',
				path: `${APP_PREFIX_PATH}/branch-manager/app-users/brides`,
				title: 'Brides',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'bm-grooms',
				path: `${APP_PREFIX_PATH}/branch-manager/app-users/grooms`,
				title: 'Grooms',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
];

export const salesAdvisorNavTree = [
	{
		key: 'sm-dashboards',
		path: `${APP_PREFIX_PATH}/sales-advisor/dashboards`,
		title: 'Dashboards',
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'sa-dashboards',
				path: `${APP_PREFIX_PATH}/sales-advisor/dashboard`,
				title: 'Dashboards',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			// {
			// 	key: 'sa-branch-performance',
			// 	path: `${APP_PREFIX_PATH}/sales-advisor/branch-performance`,
			// 	title: 'Branch Performance',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: 'sa-sales-advisor-performance',
				path: `${APP_PREFIX_PATH}/sales-advisor/sales-advisor-performance`,
				title: 'Sales Advisor Performance',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sa-Sales-advisor-activity-logs',
				path: `${APP_PREFIX_PATH}/sales-advisor/sales-advisor-activity-logs`,
				title: 'Sales Advisor Activity Logs',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			// {
			// 	key: 'sa-service-advisor-activity-logs',
			// 	path: `${APP_PREFIX_PATH}/sales-advisor/service-advisors-activity-logs`,
			// 	title: 'Service Advisor Activity Logs',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
		],
	},
	// {
	// 	key: 'sa-team-member',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/team-members`,
	// 	title: 'Team Members',
	// 	icon: UsergroupAddOutlined,
	// 	breadcrumb: false,
	// 	submenu: [
	// 		{
	// 			key: 'sa-team-members',
	// 			path: `${APP_PREFIX_PATH}/sales-advisor/team-members`,
	// 			title: 'Team Members',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 		{
	// 			key: 'sa-branch-manager',
	// 			path: `${APP_PREFIX_PATH}/sales-advisor/branch-manager`,
	// 			title: 'Branch Manager',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 		{
	// 			key: 'sa-sales-advisers',
	// 			path: `${APP_PREFIX_PATH}/sales-advisor/sales-advisers-team`,
	// 			title: 'Sales Advisers',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 		{
	// 			key: 'sa-service-advisers',
	// 			path: `${APP_PREFIX_PATH}/sales-advisor/service-advisers-team`,
	// 			title: 'Service Advisers',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 	],
	// },
	{
		key: 'sa-leads-management',
		path: `${APP_PREFIX_PATH}/sales-advisor/leads-management`,
		title: 'Leads Management',
		icon: MailOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'sa-leads-management',
				path: `${APP_PREFIX_PATH}/sales-advisor/leads-management`,
				title: 'Profile Registration',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sa-add-lead-source-status',
				path: `${APP_PREFIX_PATH}/sales-advisor/leads-management`,
				title: 'Hot Leads',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'sa-subscriptions',
		path: `${APP_PREFIX_PATH}/sales-advisor/subscriptions`,
		title: 'Subscriptions',
		icon: StarOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'sa-subscription-plans',
				path: `${APP_PREFIX_PATH}/sales-advisor/subscriptions/subscription-plans`,
				title: 'Subscription Plans',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sa-subscribed-users',
				path: `${APP_PREFIX_PATH}/sales-advisor/subscriptions/subscribed-users`,
				title: 'Subscribed Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sa-expired-subscription-users',
				path: `${APP_PREFIX_PATH}/sales-advisor/subscriptions/expired-subscription-users`,
				title: 'Expired Subscription Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'sa-app-users',
		path: `${APP_PREFIX_PATH}/sales-advisor/app-users`,
		title: 'App Users',
		icon: DesktopOutlined,
		breadcrumb: false,
		submenu: [
			// {
			// 	key: 'sa-registered-users',
			// 	path: `${APP_PREFIX_PATH}/sales-advisor/app-users/registered-users`,
			// 	title: 'Registered Users',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: 'sa-drafted-profile-users',
				path: `${APP_PREFIX_PATH}/sales-advisor/app-users/drafted-profile-users`,
				title: 'Drafted Profile Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sa-brides',
				path: `${APP_PREFIX_PATH}/sales-advisor/app-users/brides`,
				title: 'Brides',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sa-grooms',
				path: `${APP_PREFIX_PATH}/sales-advisor/app-users/grooms`,
				title: 'Grooms',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	// {
	// 	key: 'sa-user-details',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/user-details`,
	// 	title: 'User Details',
	// 	icon: UserSwitchOutlined,
	// 	breadcrumb: false,
	// 	submenu: [],
	// },
	// {
	// 	key: 'sa-user-details-preview',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/user-details-preview`,
	// 	title: 'User Details Preview',
	// 	icon: UserSwitchOutlined,
	// 	breadcrumb: false,
	// 	submenu: [],
	// },
];

export const serviceAdvisorNavTree = [
	{
		key: 'smp-dashboards',
		path: `${APP_PREFIX_PATH}/service-advisor/dashboards`,
		title: 'Dashboard',
		icon: AppstoreOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'sap-dashboards',
				path: `${APP_PREFIX_PATH}/service-advisor/dashboard`,
				title: 'Dashboard',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			// {
			// 	key: 'sap-branch-performance',
			// 	path: `${APP_PREFIX_PATH}/service-advisor/branch-performance`,
			// 	title: 'Branch Performance',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			// {
			// 	key: 'sap-sales-advisor-performance',
			// 	path: `${APP_PREFIX_PATH}/service-advisor/sales-advisor-performance`,
			// 	title: 'Sales Advisor Performance',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			// {
			// 	key: 'sap-Sales-advisor-activity-logs',
			// 	path: `${APP_PREFIX_PATH}/service-advisor/sales-advisor-activity-logs`,
			// 	title: 'Sales Advisor Activity Logs',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: 'sap-service-advisor-activity-logs',
				path: `${APP_PREFIX_PATH}/service-advisor/service-advisors-activity-logs`,
				title: 'Service Advisor Activity Logs',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'sap-profile-sharing',
		path: `${APP_PREFIX_PATH}/service-advisor/profile-sharing`,
		title: 'Profile Sharing',
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'sap-candidate-profile',
				path: `${APP_PREFIX_PATH}/service-advisor/candidate-profile`,
				title: 'Candidate Profile',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			// {
			// 	key: 'sap-shared-viewed-profile',
			// 	path: `${APP_PREFIX_PATH}/service-advisor/shared-viewed-profile`,
			// 	title: 'Shared & Viewed Profile',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			// {
			// 	key: 'sap-shared-profile-details',
			// 	path: `${APP_PREFIX_PATH}/service-advisor/shared-profile-details`,
			// 	title: 'Shared Profile Details',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			// {
			// 	key: 'sap-viewed-profile-details',
			// 	path: `${APP_PREFIX_PATH}/service-advisor/viewed-profile-details`,
			// 	title: 'Viewed Profile Details',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
		],
	},
	// {
	// 	key: 'sap-team-member',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/team-members`,
	// 	title: 'Team Members',
	// 	icon: UsergroupAddOutlined,
	// 	breadcrumb: false,
	// 	submenu: [
	// 		{
	// 			key: 'sap-team-members',
	// 			path: `${APP_PREFIX_PATH}/service-advisor/team-members`,
	// 			title: 'Team Members',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 		{
	// 			key: 'sap-branch-manager',
	// 			path: `${APP_PREFIX_PATH}/service-advisor/branch-manager`,
	// 			title: 'Branch Manager',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 		{
	// 			key: 'sap-sales-advisers',
	// 			path: `${APP_PREFIX_PATH}/service-advisor/sales-advisers-team`,
	// 			title: 'Sales Advisers',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 		{
	// 			key: 'sap-service-advisers',
	// 			path: `${APP_PREFIX_PATH}/service-advisor/service-advisers-team`,
	// 			title: 'Service Advisers',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 	],
	// },
	// {
	// 	key: 'sap-leads-management',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/leads-management`,
	// 	title: 'Leads Management',
	// 	icon: MailOutlined,
	// 	breadcrumb: false,
	// 	submenu: [
	// 		{
	// 			key: 'sap-leads-management',
	// 			path: `${APP_PREFIX_PATH}/service-advisor/leads-management`,
	// 			title: 'Leads Management',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 		{
	// 			key: 'sap-add-lead-source-status',
	// 			path: `${APP_PREFIX_PATH}/service-advisor/add-lead-source-status`,
	// 			title: 'Add Lead Source & Status',
	// 			icon: '',
	// 			breadcrumb: false,
	// 			submenu: [],
	// 		},
	// 	],
	// },
	{
		key: 'sap-subscriptions',
		path: `${APP_PREFIX_PATH}/service-advisor/subscriptions`,
		title: 'Subscriptions',
		icon: StarOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'sap-subscription-plans',
				path: `${APP_PREFIX_PATH}/service-advisor/subscriptions/subscription-plans`,
				title: 'Subscription Plans',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sap-subscribed-users',
				path: `${APP_PREFIX_PATH}/service-advisor/subscriptions/subscribed-users`,
				title: 'Subscribed Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sap-expired-subscription-users',
				path: `${APP_PREFIX_PATH}/service-advisor/subscriptions/expired-subscription-users`,
				title: 'Expired Subscription Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'sap-app-users',
		path: `${APP_PREFIX_PATH}/service-advisor/app-users`,
		title: 'App Users',
		icon: DesktopOutlined,
		breadcrumb: false,
		submenu: [
			// {
			// 	key: 'sap-registered-users',
			// 	path: `${APP_PREFIX_PATH}/service-advisor/app-users/registered-users`,
			// 	title: 'Registered Users',
			// 	icon: '',
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: 'sap-drafted-profile-users',
				path: `${APP_PREFIX_PATH}/service-advisor/app-users/drafted-profile-users`,
				title: 'Drafted Profile Users',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sap-brides',
				path: `${APP_PREFIX_PATH}/service-advisor/app-users/brides`,
				title: 'Brides',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sap-grooms',
				path: `${APP_PREFIX_PATH}/service-advisor/app-users/grooms`,
				title: 'Grooms',
				icon: '',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
];

const navigationConfig = [];

// switch (localStorage.getItem('user_type')) {
// 	case 'admin':
// 		navigationConfig = [...appsNavTree];
// 		break;
// 	case 'branch_manager':
// 		navigationConfig = [...branchManagerNavTree];
// 		break;
// 	// case 'sales_advisor':
// 	// 	navigationConfig = salesAdvisorNavTree;
// 	// 	break;
// 	// case 'service_advisor':
// 	// 	navigationConfig = serviceAdvisorNavTree;
// 	// 	break;
// 	// default:
// 	// 	navigationConfig = adminNavTree;
// 	// 	break;
// }

export default navigationConfig;
