import moment from 'moment-timezone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Import the UTC plugin for dayjs
import { userType, profileStatus } from 'constants/general';
import { isDraft } from '@reduxjs/toolkit';
dayjs.extend(utc); // Extend dayjs with the UTC plugin
export const imageBaseUrl = "https://api.sahisaathimatrimony.com";

export const getDateObject = (timestamp: string) => {
    return moment(timestamp);
}

export const getParsedProfileData = (profileData: any) => {
    if (!profileData) {
        return {}
    }
    return {
        ...profileData,
        birthTime: profileData?.birthTime ? getDateObject(profileData.birthTime) : "",
        dob: profileData?.dob ? dayjs.utc(profileData?.dob).local() : "",
        state: profileData?.address?.state,
        city: profileData?.address?.city,
        country: profileData?.address?.country,
        landmark: profileData?.address?.landmark,
        prefAgeRangeMin: profileData?.prefAgeRange?.min,
        prefAgeRangeMax: profileData?.prefAgeRange?.max,
        prefHeightRangeMin: profileData?.prefHeightRange?.min,
        prefHeightRangeMax: profileData?.prefHeightRange?.max,
        prefWeightRangeMin: profileData?.prefWeightRange?.min,
        prefWeightRangeMax: profileData?.prefWeightRange?.max,
        // caste: profileData?.title,
        // prefCaste: profileData?.title,
        annualIncome: profileData?.annualIncome?.includes("undefined") ? undefined : profileData?.annualIncome,
        prefAnnualIncomeRange: profileData?.prefAnnualIncomeRange?.includes("undefined") ? undefined : profileData?.prefAnnualIncomeRange

    }
}

export const getImageUrl = (profileMeta: any) => {
    if (Array.isArray(profileMeta)) {
        return imageBaseUrl + (profileMeta.length ? profileMeta[0] : "")
    }
    return imageBaseUrl + profileMeta?.profilePhotos
}

export const getThumbNailUrl = (profileUrl: any) => {
    return imageBaseUrl + profileUrl
}

export const extractTimeFromDateString = (dateString: string): string => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Return an empty string if the date is invalid

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
};

// Usage example
const birthTime = "2024-08-12T23:36:06.763Z";
const formattedTime = extractTimeFromDateString(birthTime);

export const formatDateToDDMMYYYY = (input: any) => {

    // Check if input is a Date object or has a $d property
    let date;
    if (input instanceof Date) {
        date = moment(input);
    } else if (input && input.$d instanceof Date) {
        date = moment(input.$d);
    } else if (typeof input === 'string') {
        date = moment(input);
    } else {
        // Handle unexpected input formats
        return '-';
    }


    // Convert to specified timezone and format
    const formattedDate = date
        .tz('Asia/Kolkata', true) // true for keeping the local time but changing the timezone
        .format('DD/MM/YYYY');

    return formattedDate;
};
export const formatTimeToHHmm = (dateString: string) => {
    if (!dateString) {
        return '-';
    }
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return '-';
    }

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}
export const convertDecimalMinute = (value: string) => {
    // Extract the hours from the string
    let hours = parseInt(value.split(' ')[0]);

    // Extract the numeric part of minutes by splitting at 'hours' and 'minutes' correctly
    let minutes = parseFloat(value.split(' ')[1]);

    // Check if minutes is NaN, just in case parsing fails
    if (isNaN(minutes)) {
        return `${hours} hours 0 minutes`;
    }

    // Round the minutes to the nearest whole number
    let roundedMinutes = Math.round(minutes);

    return (`${hours} hours ${roundedMinutes} minutes`);
}

export const convertData = (value: string) => {
    if (value) {

        return moment(value).local().format('DD/MM/YYYY - hh:mm A');
    }
    return
}



export const fileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

export const getBase64Image = async (fileList: any) => {
    return await Promise.all(
        fileList.map(async (file: any) => {
            if (file.originFileObj) {
                const base64 = await fileToBase64(file.originFileObj);
                return base64;
            }
            return null;
        }),
    );
}

export const removeStringFromArray = (arr: Array<string>, str: string) => {
    return arr.filter(item => item !== str);
}

export const removePlusFromContactNumber = (value: string) => {
    // Check if the phone number starts with "+91"
    if (!value) return value;
    if (value.startsWith('+91')) {
        // Remove the country code
        return value.substring(3);
    } else {
        // The country code is not present, return the original number
        return value;
    }
}

export const cleanNullKeys = (obj: any) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined && v !== null));
export const setEmptyStringForUndefinedKeys = (obj: any) => {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, value === undefined ? '' : value])
    );
};

export const getDataFromLocalStorage = {
    userId: localStorage.getItem('user_id'),
    token: localStorage.getItem('token'),
    userType: localStorage.getItem('user_type'),
    branch: localStorage.getItem('user_branch'),
    userName: localStorage.getItem('user_name')
}

export const commonTableFilterConfig = {
    pageSize: 5,
    page: 1,
    sortBy: 'updatedOn',
    sortOrder: 'desc',
}

const commonBaseFilter = {
    profileReviewStatus: [profileStatus.pending, profileStatus.approved, profileStatus.rejected],
    ...commonTableFilterConfig,
    isDraft: false
}

export const baseProfileFilter = {
    [userType.admin]: {
        ...commonBaseFilter
    },
    [userType.branchManager]: {
        branch: getDataFromLocalStorage.branch,
        ...commonBaseFilter
    },
    [userType.salesAdviser]: {
        branch: getDataFromLocalStorage.branch,
        salesAdvisor: getDataFromLocalStorage.userId,
        ...commonBaseFilter
    },
    [userType.serviceAdviser]: {
        ...commonBaseFilter,
        branch: getDataFromLocalStorage.branch,
        // serviceAdvisor: getDataFromLocalStorage.userId,
        profileReviewStatus: [profileStatus.approved],
    }
}

export const baseDraftProfileFilter = {
    bride: {
        [userType.admin]: {
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.approved],
            gender: "female"
        },
        [userType.branchManager]: {
            branch: getDataFromLocalStorage.branch,
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.approved],
            gender: "female"
        },
        [userType.salesAdviser]: {
            branch: getDataFromLocalStorage.branch,
            // salesAdvisor: getDataFromLocalStorage.userId,
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.approved],
            gender: "female"
        },
        [userType.serviceAdviser]: {
            branch: getDataFromLocalStorage.branch,
            // salesAdvisor: getDataFromLocalStorage.userId,
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.approved],
            gender: "female"
        }
    },
    groom: {
        [userType.admin]: {
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.approved],
            gender: "male"
        },
        [userType.branchManager]: {
            branch: getDataFromLocalStorage.branch,
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.approved],
            gender: "male"
        },
        [userType.salesAdviser]: {
            branch: getDataFromLocalStorage.branch,
            // salesAdvisor: getDataFromLocalStorage.userId,
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.approved],
            gender: "male"
        },
        [userType.serviceAdviser]: {
            branch: getDataFromLocalStorage.branch,
            // salesAdvisor: getDataFromLocalStorage.userId,
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.approved],
            gender: "male"
        }
    },
    draft: {
        [userType.admin]: {
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.pending, profileStatus.approved, profileStatus.rejected],
            isDraft: true
        },
        [userType.branchManager]: {
            branch: getDataFromLocalStorage.branch,
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.pending, profileStatus.approved, profileStatus.rejected],
            isDraft: true
        },
        [userType.salesAdviser]: {
            branch: getDataFromLocalStorage.branch,
            // salesAdvisor: getDataFromLocalStorage.userId,
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.pending, profileStatus.approved, profileStatus.rejected],
            isDraft: true
        },
        [userType.serviceAdviser]: {
            branch: getDataFromLocalStorage.branch,
            // salesAdvisor: getDataFromLocalStorage.userId,
            ...commonBaseFilter,
            profileReviewStatus: [profileStatus.pending, profileStatus.approved, profileStatus.rejected],
            isDraft: true
        }

    }
}

export const defaultPaginationObject = {
    pageSize: 10,
    page: 1,
    total: 0
}

export const subscribedProfileBaseFilter = {
    isActive: "",
    branchId: "",
    startDate: "",
    endDate: "",
    subscriptionId: "",
    salesAdvId: "",
    paymentMethod: "",
    // pageSize: 10,
    // page: 1
}

export const baseTableFilter = {
    page: 1,
    pageSize: 10,
    sortBy: "fullName",
    sortOrder: 'desc'
}

export const roleBasedSubscribedProfileFilter = {
    [userType.admin]: {
        ...subscribedProfileBaseFilter
    },
    [userType.branchManager]: {
        ...subscribedProfileBaseFilter,
        branchId: getDataFromLocalStorage.branch
    },
    [userType.salesAdviser]: {
        ...subscribedProfileBaseFilter,
        branchId: getDataFromLocalStorage.branch,
        salesAdvId: getDataFromLocalStorage.userId
    },
    [userType.serviceAdviser]: {
        ...subscribedProfileBaseFilter,
        branchId: getDataFromLocalStorage.branch
    }
}

export const formatDate = (isoDateString: string) => {
    // Parse the ISO date string into a Date object
    const date = new Date(isoDateString);

    // Extract day, month, and year from the Date object
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    // Return the formatted date string
    return `${day}/${month}/${year}`;
};

export function convertToDdMmYyyy(dateString: string) {
    const [month, day, year] = dateString.split('/');
    return `${day}-${month}-${year}`;
}

export const formatTimestamp = (timestamp: string) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
};


export function compareTokenCreationWithCurrentDate(iatTimestamp: number) {
    // Convert the Unix timestamp to a Date object
    const tokenDate = new Date(iatTimestamp * 1000); // Multiply by 1000 to convert to milliseconds
    console.log(tokenDate, "377")
    // Get today's date in UTC (no time component)
    const today = new Date();
    const todayDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
    console.log(todayDate, "379")
    // Compare the dates (ignoring time component)
    return tokenDate.getUTCFullYear() === todayDate.getUTCFullYear() &&
        tokenDate.getUTCMonth() === todayDate.getUTCMonth() &&
        tokenDate.getUTCDate() === todayDate.getUTCDate();
}