import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onBlankLayout } from 'store/slices/themeSlice';

const axiosInstance = axios.create({
	baseURL: 'https://api.sahisaathimatrimony.com',
	timeout: 100000,
});

let dispatch;
let navigate;

const useAxios = () => {
	dispatch = useDispatch();
	navigate = useNavigate();
};

axiosInstance.interceptors.request.use(
	(config) => {
		// Add authorization token or other headers here
		const token = localStorage.getItem('token');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	(response) => {
		// Any status code within the range of 2xx causes this function to trigger
		return response?.data;
	},
	(error) => {
		// Any status codes outside the range of 2xx cause this function to trigger
		// Handle errors globally here
		if (error.response && error.response.status === 401) {
			navigate('/auth/login', { redirect: true });
			localStorage.clear();
			dispatch(onBlankLayout(true));
		}
		return Promise.reject(error);
	},
);

export default axiosInstance;
