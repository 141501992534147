import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import teamMember from './slices/teamMemberSlice';
import branch from './slices/branchSlice';
import profile from './slices/profileSlice';
import subscription from './slices/subscription';
import lead from './slices/leadSlice'
import analytics from './slices/analytics';
import salesActivity from "./slices/salesActivityLogSlice"
import serviceActivity from './slices/serviceActivityLogSlice';

const rootReducer = (asyncReducers) => (state, action) => {
	const combinedReducer = combineReducers({
		theme,
		auth,
		teamMember,
		branch,
		profile,
		subscription,
		lead,
		analytics,
		salesActivity,
		serviceActivity,
		...asyncReducers,
	});
	return combinedReducer(state, action);
};

export default rootReducer;
