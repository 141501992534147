import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'api/axios';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
};

export const getServiceActivity = createAsyncThunk('serviceActivity/getServiceActivity', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`/emp-activity/list?page=${data?.pagination?.currentPage}&pageSize=${data?.pagination?.pageSize}`, data?.filter);

        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});


export const serviceActivityLogSlice = createSlice({
    name: 'serviceActivity',
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getServiceActivity.pending, (state) => {
                state.loading = true;
            })
            .addCase(getServiceActivity.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(getServiceActivity.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })


    },
});

export const { showLoading } = serviceActivityLogSlice.actions;

export default serviceActivityLogSlice.reducer;
