import * as structs from "./structs.js";

export const mergeAddPlanRequestObject = (p) =>
    new Promise(async (resolve) => {
        let payload = Object.assign({}, structs.subscriptionPlan);
        payload.name = p?.name || "";
        payload.price = +p?.price || "";
        payload.profilePerPlan = +p?.profilePerPlan || 0;
        payload.weeklyProfileCount = +p?.weeklyProfileCount || 0;
        payload.planAsstFeature = p?.planAsstFeature || "";
        payload.isActive = p?.isActive || false;
        resolve(payload);
    });

export const mergeEditPlanRequestObject = (p) =>
    new Promise(async (resolve) => {
        let payload = Object.assign({}, structs.subscriptionPlan);
        payload.name = p?.name || "";
        payload.price = +p?.price || "";
        payload.profilePerPlan = +p?.profilePerPlan || 0;
        payload.weeklyProfileCount = +p?.weeklyProfileCount || 0;
        payload.planAsstFeature = p?.planAsstFeature || "";
        payload.isActive = p?.isActive || false;
        resolve(payload);
    });

export const mergeBaseCounts = (p) => new Promise(async (resolve) => {
    const payload = [
        {
            title: "Total Leads Assigned",
            value: String(p?.assignedLeadsCount),
            subtitle: "Leads"
        },
        {
            title: "Total Calls Made",
            value: String(p?.totalCalledCount),
            subtitle: "Calls"
        },
        {
            title: "Total Subscriptions Sold",
            value: String(p?.subscriptionSoldCount),
            subtitle: "Subscriptions"
        },
        {
            title: "Conversion Rate",
            value: `${p?.conversionRate}%`,
            subtitle: "Percentage"
        }
    ];
    resolve(payload)
})